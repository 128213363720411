import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import ServiceBossLogo from './ServiceBossLogo';

function Header() {
  return (
    <AppBar position="static">
      <Box sx={{ maxWidth: '62%', width: '100%', margin: '0 auto' }}>
        <Toolbar disableGutters sx={{ flexWrap: 'wrap', width: '100%', padding: '16px 0' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Link to="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
              <ServiceBossLogo width={60} height={60} style={{ marginRight: 16 }} />
              <Box>
                <Typography variant="h4" color="primary" noWrap>
                  ServiceBoss
                </Typography>
                <Typography variant="subtitle2" color="secondary" noWrap>
                  a Pixolomew creation
                </Typography>
              </Box>
            </Link>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
}

export default Header;
