import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Typography, Grid, Button, Paper } from '@mui/material';
import MaintenanceLogForm from './MaintenanceLogForm';

function WebsitePage({ websites, logs, addLog }) {
  const { id } = useParams();
  const website = websites.find(w => w.id === id);
  const websiteLogs = logs.filter(log => log.websiteId === id);

  const mostRecentLog = websiteLogs.length > 0 
    ? websiteLogs.reduce((prev, current) => 
        (new Date(prev.dateOfService) > new Date(current.dateOfService)) ? prev : current
      )
    : null;

  const handleAddLog = (newLog) => {
    addLog({ ...newLog, websiteId: id });
  };

  console.log('Current website ID:', id);
  console.log('All websites:', websites);
  console.log('Found website:', website);

  if (!website) {
    return <div>Website not found</div>;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>{website.name}</Typography>
      </Grid>
      
      {/* Existing Logs Section */}
      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
          <Typography variant="h5" gutterBottom>Maintenance Logs</Typography>
          {websiteLogs.length > 0 ? (
            <Grid container spacing={2}>
              {websiteLogs.map(log => (
                <Grid item key={log.id} xs={12} sm={6} md={4}>
                  <Link 
                    to={`/website/${id}/log/${log.id}`}
                    style={{
                      display: 'block',
                      padding: '12px 24px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      textDecoration: 'none',
                      color: 'inherit',
                      fontSize: '1.1rem',
                      transition: 'background-color 0.3s',
                    }}
                    onMouseEnter={(e) => e.target.style.backgroundColor = '#f5f5f5'}
                    onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}
                  >
                    {new Date(log.dateOfService).toLocaleDateString()} - View Details
                  </Link>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography>No maintenance logs yet.</Typography>
          )}
        </Paper>
      </Grid>
      
      {/* Maintenance Log Form */}
      <Grid item xs={12}>
        <MaintenanceLogForm 
          onSubmit={handleAddLog} 
          websiteName={website.name}
          previousPlugins={mostRecentLog ? mostRecentLog.plugins : []}
          previousThemes={mostRecentLog ? mostRecentLog.themes : []}
        />
      </Grid>
    </Grid>
  );
}

export default WebsitePage;
