import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Button, Box, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MaintenanceLogDetails from './MaintenanceLogDetails';
import MaintenanceLogForm from './MaintenanceLogForm';

function LogDetailsPage({ logs, websites, updateLog, deleteLog }) {
  const { websiteId, logId } = useParams();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const theme = useTheme();
  
  const log = logs.find(l => l.id === logId);
  const website = websites.find(w => w.id === websiteId);

  if (!log || !website) {
    return <Typography>Log or Website not found</Typography>;
  }

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleUpdate = (updatedLog) => {
    updateLog({ ...updatedLog, id: logId, websiteId });
    setIsEditing(false);
  };

  const handleDeleteClick = () => {
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = () => {
    deleteLog(logId);
    setDeleteConfirmOpen(false);
    navigate(`/website/${websiteId}`);
  };

  return (
    <Box>
      <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
        <Grid item>
          <Button onClick={() => navigate(`/website/${websiteId}`)} variant="contained">
            Back to {website.name}
          </Button>
        </Grid>
        {!isEditing && (
          <>
            <Grid item>
              <Button onClick={handleEdit} variant="outlined" color="primary">
                Edit Log
              </Button>
            </Grid>
            <Grid item>
              <Button 
                onClick={handleDeleteClick} 
                variant="contained" 
                style={{ 
                  backgroundColor: theme.palette.error.main,
                  color: theme.palette.error.contrastText
                }}
              >
                Delete Log
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      
      {isEditing ? (
        <MaintenanceLogForm
          onSubmit={handleUpdate}
          initialValues={log}
          websiteName={website.name}
        />
      ) : (
        <MaintenanceLogDetails log={log} />
      )}

      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Log Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this log? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleDeleteConfirm} 
            style={{ 
              backgroundColor: theme.palette.error.main,
              color: theme.palette.error.contrastText
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default LogDetailsPage;
