import { createTheme } from '@mui/material/styles';

const pixolomewGreen = '#14766A';
const pixolomewOrange = '#DD5B37';
const pixolomewRed = '#7C3C43';

const theme = createTheme({
  palette: {
    primary: {
      main: pixolomewRed, // Updated to use pixolomewRed for primary color
    },
    secondary: {
      main: '#344E6B', // This is now used for field labels and secondary elements
    },
    background: {
      default: '#F1EEE7', // Light beige background
      paper: '#F3F8F7', // Updated background for content areas
    },
    text: {
      primary: '#111318', // This sets the main body text color
    },
    error: {
      main: pixolomewRed,
    },
    success: {
      main: pixolomewGreen,
    },
    warning: {
      main: pixolomewOrange,
    },
  },
  typography: {
    fontFamily: '"Libre Franklin", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontFamily: '"Libre Franklin", "Roboto", "Helvetica", "Arial", sans-serif',
      color: pixolomewRed,
      fontWeight: 700, // Bold
    },
    h2: {
      fontFamily: '"Libre Franklin", "Roboto", "Helvetica", "Arial", sans-serif',
      color: pixolomewRed,
      fontWeight: 700, // Bold
    },
    h3: {
      fontFamily: '"Libre Franklin", "Roboto", "Helvetica", "Arial", sans-serif',
      color: pixolomewRed,
      fontWeight: 700, // Bold
    },
    h4: {
      fontFamily: '"Libre Franklin", "Roboto", "Helvetica", "Arial", sans-serif',
      color: pixolomewRed,
      fontWeight: 700, // Already bold, but explicitly set for consistency
    },
    h5: {
      fontFamily: '"Libre Franklin", "Roboto", "Helvetica", "Arial", sans-serif',
      color: pixolomewRed,
      fontWeight: 700, // Bold
    },
    h6: {
      fontFamily: '"Libre Franklin", "Roboto", "Helvetica", "Arial", sans-serif',
      color: pixolomewRed,
      fontWeight: 700, // Bold
      fontSize: '1.25rem',
      marginTop: '16px',
      marginBottom: '8px',
    },
    subtitle1: {
      fontFamily: '"Libre Franklin", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    subtitle2: {
      fontFamily: '"Libre Franklin", "Roboto", "Helvetica", "Arial", sans-serif',
      fontStyle: 'italic',
    },
    body1: {
      fontFamily: '"Libre Franklin", "Roboto", "Helvetica", "Arial", sans-serif',
      color: '#111318',
    },
    body2: {
      fontFamily: '"Libre Franklin", "Roboto", "Helvetica", "Arial", sans-serif',
      color: '#111318',
    },
    button: {
      fontFamily: '"Libre Franklin", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
      fontSize: '1.1rem',
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        color: 'transparent',
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderBottom: '1px solid #E0E0E0',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          backgroundColor: pixolomewGreen,
          color: '#FFFFFF',
          fontWeight: 700,
          fontSize: '1.1rem',
          '&:hover': {
            backgroundColor: pixolomewOrange,
          },
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        text: {
          backgroundColor: 'transparent',
          color: 'inherit',
          fontWeight: 400,
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#344E6B',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#344E6B',
        },
      },
    },
  },
});

export default theme;
