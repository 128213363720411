import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { openDB } from 'idb';
import theme from './theme';
import Header from './components/Header';
import Footer from './components/Footer';
import WebsiteList from './components/WebsiteList';
import WebsitePage from './components/WebsitePage';
import LogDetailsPage from './components/LogDetailsPage';

// Initialize the database
const initDB = async () => {
  return openDB('WebsiteManagerDB', 1, {
    upgrade(db) {
      db.createObjectStore('websites', { keyPath: 'id' });
      db.createObjectStore('logs', { keyPath: 'id' });
    },
  });
};

function App() {
  const [websites, setWebsites] = useState([]);
  const [logs, setLogs] = useState([]);
  const [db, setDB] = useState(null);

  useEffect(() => {
    const setupDB = async () => {
      const database = await initDB();
      setDB(database);
      
      // Load initial data
      const storedWebsites = await database.getAll('websites');
      const storedLogs = await database.getAll('logs');
      setWebsites(storedWebsites);
      setLogs(storedLogs);
    };
    setupDB();
  }, []);

  const addWebsite = async (name) => {
    if (!db) return;
    const newWebsite = { id: Date.now().toString(), name };
    await db.add('websites', newWebsite);
    setWebsites(prevWebsites => {
      const updatedWebsites = [...prevWebsites, newWebsite];
      console.log('New website added:', newWebsite);
      console.log('Updated websites:', updatedWebsites);
      return updatedWebsites;
    });
    return newWebsite;
  };

  const deleteWebsite = async (id) => {
    if (!db) return;
    await db.delete('websites', id);
    setWebsites(websites.filter(website => website.id !== id));
    // Also delete associated logs
    const updatedLogs = logs.filter(log => log.websiteId !== id);
    setLogs(updatedLogs);
    await Promise.all(updatedLogs.map(log => db.put('logs', log)));
  };

  const addLog = async (log) => {
    if (!db) return;
    const newLog = { ...log, id: Date.now().toString() };
    await db.add('logs', newLog);
    setLogs([...logs, newLog]);
    return newLog;
  };

  const updateLog = async (updatedLog) => {
    if (!db) return;
    await db.put('logs', updatedLog);
    setLogs(logs.map(log => log.id === updatedLog.id ? updatedLog : log));
  };

  const deleteLog = async (id) => {
    if (!db) return;
    await db.delete('logs', id);
    setLogs(logs.filter(log => log.id !== id));
  };


  
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <Router>
          <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header />
            <main style={{ flexGrow: 1, width: '100%', maxWidth: '62%', margin: '0 auto', padding: '24px 0 48px' }}>
              <Routes>
                <Route path="/" element={<WebsiteList websites={websites} addWebsite={addWebsite} deleteWebsite={deleteWebsite} />} />
                <Route path="/website/:id" element={<WebsitePage websites={websites} logs={logs} addLog={addLog} />} />
                <Route 
                  path="/website/:websiteId/log/:logId" 
                  element={<LogDetailsPage logs={logs} websites={websites} updateLog={updateLog} deleteLog={deleteLog} />} 
                />
              </Routes>
            </main>
            <Footer />
          </div>
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
