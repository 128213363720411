import React from 'react';

function ServiceBossLogo({ width = '100%', height = '100%', ...props }) {
  return (
    <svg width={width} height={height} viewBox="0 0 496 577" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style={{fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: 2}} {...props}>
      <g id="shield">
        <path d="M69.033,102.039c3.223,66.46 12.55,186.701 39.383,293.719c14.931,16.762 75.657,81.99 139.503,109.59c63.87,-27.6 124.575,-92.828 139.5,-109.583c26.835,-107.017 36.165,-227.262 39.383,-293.726c-41.033,-12.963 -103.605,-28.69 -178.883,-34.496c-75.276,5.806 -137.853,21.533 -178.886,34.496Z" style={{fill: "#14766a", fillRule: "nonzero"}}/>
        <path d="M444.674,96.124c-2.962,65.898 -12.187,193.284 -40.74,306.016l-0.517,2.055l-1.403,1.598c-12.727,14.46 -79.815,88.095 -150.757,117.217l-3.338,1.373l-3.336,-1.373c-70.916,-29.122 -138.026,-102.757 -150.759,-117.225l-1.402,-1.59l-0.521,-2.055c-28.55,-112.74 -37.776,-240.121 -40.735,-306.016l-0.299,-6.674l6.351,-2.072c41.805,-13.642 108.71,-31.205 190.034,-37.407l0.667,-0.051l0.668,0.051c81.322,6.202 148.23,23.765 190.035,37.407l6.352,2.072l-0.3,6.674Zm-196.755,-96.124c-145.701,10.336 -247.919,55.355 -247.919,55.355c0.93,46.473 7.033,223.208 47.087,372.225c0.02,0.075 0.052,0.143 0.102,0.203c2.932,3.6 97.302,118.612 200.73,149.16c103.425,-30.548 197.798,-145.56 200.73,-149.16c0.053,-0.06 0.083,-0.128 0.105,-0.203c40.05,-149.017 46.155,-325.752 47.085,-372.225c0,0 -102.217,-45.019 -247.92,-55.355Z" style={{fill: "#111318", fillRule: "nonzero"}}/>
      </g>
      <path d="M138.596,227.255l24.811,-27.568l15.162,41.352l23.849,-33.637l34.459,6.892l-41.767,52.934l20.676,35.838l-15.162,15.162l-22.055,-28.945l-23.433,35.838l-23.432,-4.134l33.081,-53.759l-26.189,-39.973Z" style={{fill: "#f1eee7", fillRule: "nonzero"}}/>
      <path d="M317.186,246.231c0.731,22.521 -15.674,48.031 -37.965,43.178c-27.712,-6.036 -24.912,-39.727 -12.95,-55.863c7.271,-9.811 26.594,-22.919 40.207,-11.551c7.107,5.935 10.403,14.825 10.708,24.236m4.857,-52.804c-14.876,-9.461 -34.837,-8.061 -49.114,0.428c-29.431,17.501 -53.493,70.69 -23.781,103.362c13.724,15.088 37.558,17.282 54.391,8.766c16.834,-8.515 27.482,-25.487 33.734,-43.387c3.784,-10.84 6.229,-22.467 4.964,-34.293c-1.265,-11.825 -6.598,-23.911 -16.061,-31.849c-1.324,-1.111 -2.705,-2.119 -4.133,-3.027" style={{fill: "#f1eee7", fillRule: "nonzero"}}/>
      <path d="M257.906,183.886c0,0 63.608,-56.393 94.753,5.896l11.475,-9.835c0,0 -25.909,-82.937 -117.702,-12.452l11.474,16.391Z" style={{fill: "#f1eee7", fillRule: "nonzero"}}/>
      <path d="M205.206,380.042c-5.553,0 -11.103,-0.154 -16.646,-0.466l-5.632,-0.317l-2.252,-5.171c-4.761,-10.919 -6.802,-17.858 -9.623,-27.458l-1.232,-4.18l17.569,-5.211l1.243,4.22c2.221,7.546 3.827,13.014 6.654,20.081c33.502,1.185 67.249,-3.675 98.695,-14.21c-3.968,-3.693 -7.587,-7.354 -11.729,-11.8l13.411,-12.49c7.177,7.706 12.692,12.885 21.855,20.529l11.806,9.843l-14.276,5.703c-34.501,13.783 -72.163,20.927 -109.843,20.927" style={{fill: "#f1eee7", fillRule: "nonzero"}}/>
    </svg>
  );
}

export default ServiceBossLogo;