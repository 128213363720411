import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  List, 
  ListItem, 
  ListItemText, 
  Paper, 
  Typography, 
  TextField, 
  Button, 
  Grid, 
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function WebsiteList({ websites, addWebsite, deleteWebsite }) {
  const [newWebsiteName, setNewWebsiteName] = useState('');
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newWebsiteName.trim()) {
      await addWebsite(newWebsiteName.trim());
      setNewWebsiteName('');
    }
  };

  const handleDeleteClick = (website) => {
    setDeleteConfirmation(website);
  };

  const handleDeleteConfirm = () => {
    if (deleteConfirmation) {
      deleteWebsite(deleteConfirmation.id);
      setDeleteConfirmation(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmation(null);
  };

  return (
    <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
      <Typography variant="h5" gutterBottom>
        Websites
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={9}>
            <TextField
              fullWidth
              label="New Website Name"
              value={newWebsiteName}
              onChange={(e) => setNewWebsiteName(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <Button 
              type="submit" 
              variant="contained" 
              color="primary" 
              fullWidth 
              style={{ height: '100%' }}
            >
              Add Website
            </Button>
          </Grid>
        </Grid>
      </form>
      <List>
        {websites.map((website) => (
          <ListItem
            key={website.id}
            secondaryAction={
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteClick(website)}>
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemText
              primary={
                <Link to={`/website/${website.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  {website.name}
                </Link>
              }
            />
          </ListItem>
        ))}
      </List>
      <Dialog
        open={Boolean(deleteConfirmation)}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the website "{deleteConfirmation?.name}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default WebsiteList;
