import React from 'react';
import { Typography, Box } from '@mui/material';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <Box sx={{ bgcolor: 'background.paper', py: 3 }} component="footer">
      <Box sx={{ maxWidth: '62%', width: '100%', margin: '0 auto' }}>
        <Typography variant="body2" color="text.secondary" align="left">
          © {currentYear} ServiceBoss. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
