import React from 'react';
import { Paper, Typography, Grid, Chip, Divider } from '@mui/material';
import { format } from 'date-fns';

function MaintenanceLogDetails({ log }) {
  const renderSection = (title, content) => (
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>{title}</Typography>
      {content}
      <Divider style={{ margin: '20px 0' }} />
    </Grid>
  );

  const renderStatusChip = (status) => {
    let color = 'default';
    if (status === 'Passed' || status === 'Good' || status === 'No Errors') color = 'success';
    if (status === 'Needs Improvement' || status === 'Minor Errors') color = 'warning';
    if (status === 'Failed' || status === 'Critical' || status === 'Major Errors') color = 'error';
    
    return (
      <Chip 
        label={status} 
        color={color} 
        sx={{
          borderRadius: '7px',
          fontWeight: 'bold',
          fontSize: '1.2rem',
          padding: '24px 12px', // Increased top and bottom padding
        }}
      />
    );
  };

  const renderWordPress = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1">Current Version: {log.wordpress.currentVersion}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1">Update Required: {log.wordpress.updateRequired ? 'Yes' : 'No'}</Typography>
      </Grid>
      {log.wordpress.updateRequired && (
        <Grid item xs={12} sm={4}>
          <Typography variant="body1">Updated Version: {log.wordpress.updatedVersion}</Typography>
        </Grid>
      )}
    </Grid>
  );

  const renderItems = (items, type) => (
    <Grid container spacing={2}>
      {items.map((item, index) => (
        <Grid item xs={12} key={index}>
          <Paper elevation={1} style={{ padding: '10px' }}>
            <Typography variant="subtitle1">{item.name}</Typography>
            <Typography variant="body2">Current Version: {item.currentVersion}</Typography>
            <Typography variant="body2">Update Required: {item.updateRequired ? 'Yes' : 'No'}</Typography>
            {item.updateRequired && (
              <Typography variant="body2">Updated Version: {item.updatedVersion}</Typography>
            )}
          </Paper>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
      <Typography variant="h4" gutterBottom>{log.websiteName} Maintenance Log</Typography>
      <Typography variant="subtitle1" gutterBottom>
        Date of Service: {format(new Date(log.dateOfService), 'MMMM d, yyyy')}
      </Typography>
      <Divider style={{ margin: '20px 0' }} />

      {renderSection('WordPress', renderWordPress())}
      {renderSection('Plugins', renderItems(log.plugins, 'plugin'))}
      {renderSection('Themes', renderItems(log.themes, 'theme'))}

      {renderSection('WordPress Integrity Test Results', renderStatusChip(log.wordpressIntegrityResults))}
      {renderSection('Error Log Status', (
        <>
          {renderStatusChip(log.errorLogStatus)}
          {(log.errorLogStatus === 'Minor Errors' || log.errorLogStatus === 'Major Errors') && (
            <Typography variant="body1" style={{ marginTop: '10px' }}>{log.errorLogDetails}</Typography>
          )}
        </>
      ))}
      {renderSection('Site Health Status', (
        <>
          {renderStatusChip(log.siteHealthStatus)}
          {(log.siteHealthStatus === 'Needs Improvement' || log.siteHealthStatus === 'Critical') && (
            <Typography variant="body1" style={{ marginTop: '10px' }}>{log.siteHealthDetails}</Typography>
          )}
        </>
      ))}

      {renderSection('Next Checkup', (
        <Typography variant="body1">
          {format(new Date(log.nextCheckupDate), 'MMMM d, yyyy')}
        </Typography>
      ))}

      <Typography variant="body2">Email sent to: {log.emailTo}</Typography>
    </Paper>
  );
}

export default MaintenanceLogDetails;
