import React, { useState, useEffect } from 'react';
import { Button, TextField, Grid, Paper, Typography, FormControl, InputLabel, Select, MenuItem, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ButtonGroup, FormHelperText, Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { addWeeks, addMonths } from 'date-fns';
import axios from 'axios';
import ServiceBossLogo from './ServiceBossLogo';
import ReactDOMServer from 'react-dom/server';

function MaintenanceLogForm({ onSubmit, initialValues = null, websiteName, previousPlugins = [], previousThemes = [] }) {
  const [log, setLog] = useState(initialValues || {
    websiteName: websiteName || '',
    dateOfService: new Date(),
    plugins: previousPlugins.map(plugin => ({...plugin, updateRequired: false, currentVersion: '', updatedVersion: ''})),
    themes: previousThemes.map(theme => ({...theme, updateRequired: false, currentVersion: '', updatedVersion: ''})),
    wordpress: { updateRequired: false, currentVersion: '', updatedVersion: '' },
    wordpressIntegrityResults: '',
    errorLogStatus: '',
    errorLogDetails: '',
    siteHealthStatus: '',
    siteHealthDetails: '',
    nextCheckupDate: new Date(),
    emailTo: '',
  });

  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (websiteName && !initialValues) {
      setLog(prevLog => ({ 
        ...prevLog, 
        websiteName,
        plugins: previousPlugins.map(plugin => ({...plugin, updateRequired: false, currentVersion: '', updatedVersion: ''})),
        themes: previousThemes.map(theme => ({...theme, updateRequired: false, currentVersion: '', updatedVersion: ''})),
      }));
    }
  }, [websiteName, initialValues, previousPlugins, previousThemes]);

  const validateForm = () => {
    let tempErrors = {};
    tempErrors.websiteName = log.websiteName ? "" : "Website name is required";
    tempErrors.dateOfService = log.dateOfService ? "" : "Date of service is required";
    tempErrors.wordpress = log.wordpress.currentVersion ? "" : "WordPress current version is required";
    tempErrors.wordpressIntegrityResults = log.wordpressIntegrityResults ? "" : "WordPress integrity test result is required";
    tempErrors.errorLogStatus = log.errorLogStatus ? "" : "Error log status is required";
    tempErrors.siteHealthStatus = log.siteHealthStatus ? "" : "Site health status is required";
    tempErrors.nextCheckupDate = log.nextCheckupDate ? "" : "Next checkup date is required";
    tempErrors.emailTo = log.emailTo ? "" : "Email recipient is required";

    // Validate plugins and themes
    log.plugins.forEach((plugin, index) => {
      if (!plugin.name) {
        tempErrors[`plugins[${index}].name`] = "Plugin name is required";
      }
    });
    log.themes.forEach((theme, index) => {
      if (!theme.name) {
        tempErrors[`themes[${index}].name`] = "Theme name is required";
      }
    });

    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLog({ ...log, [name]: value });
  };

  const handleDateChange = (name) => (date) => {
    setLog({ ...log, [name]: date });
    if (name === 'dateOfService') {
      // Update nextCheckupDate when dateOfService changes
      setLog(prevLog => ({
        ...prevLog,
        nextCheckupDate: addWeeks(date, 2) // Default to 2 weeks from new date of service
      }));
    }
  };

  const handleWordpressChange = (field, value) => {
    setLog({ ...log, wordpress: { ...log.wordpress, [field]: value } });
  };

  const svgToDataURL = (svg) => {
    return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;
  };

  const formatLogForEmail = (log) => {
    const svgString = ReactDOMServer.renderToStaticMarkup(<ServiceBossLogo width="100" height="116" />);
    const logoDataURL = svgToDataURL(svgString);
    return `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px; background-color: #F1EEE7; color: #111318;">
        <div style="background-color: #F3F8F7; padding: 20px;">
          <img src="${logoDataURL}" alt="Service Boss Logo" style="width: 100px; height: auto; display: block; margin-bottom: 20px;" />
          <h1 style="color: #7C3C43; font-size: 24px; margin-bottom: 20px; font-weight: bold;">Maintenance Log for ${log.websiteName}</h1>
          
          <div style="margin-bottom: 20px; border-bottom: 1px solid #e0e0e0; padding-bottom: 20px;">
            <p><strong style="color: #344E6B;">Date of Service:</strong> ${log.dateOfService.toLocaleDateString()}</p>
            <p><strong style="color: #344E6B;">WordPress Version:</strong> ${log.wordpress.currentVersion}</p>
            <p><strong style="color: #344E6B;">WordPress Update Required:</strong> ${log.wordpress.updateRequired ? 'Yes' : 'No'}</p>
            ${log.wordpress.updateRequired ? `<p><strong style="color: #344E6B;">Updated WordPress Version:</strong> ${log.wordpress.updatedVersion}</p>` : ''}
          </div>
          
          <h2 style="color: #7C3C43; font-size: 20px; margin-top: 20px; font-weight: bold;">Plugins</h2>
          <ul style="list-style-type: none; padding: 0;">
            ${log.plugins.map(p => `
              <li style="margin-bottom: 10px;">
                <strong style="color: #344E6B;">${p.name}</strong> - 
                Current: ${p.currentVersion}, 
                Update Required: ${p.updateRequired ? 'Yes' : 'No'}
                ${p.updateRequired ? `, Updated: ${p.updatedVersion}` : ''}
              </li>
            `).join('')}
          </ul>
          
          <h2 style="color: #7C3C43; font-size: 20px; margin-top: 20px; font-weight: bold;">Themes</h2>
          <ul style="list-style-type: none; padding: 0;">
            ${log.themes.map(t => `
              <li style="margin-bottom: 10px;">
                <strong style="color: #344E6B;">${t.name}</strong> - 
                Current: ${t.currentVersion}, 
                Update Required: ${t.updateRequired ? 'Yes' : 'No'}
                ${t.updateRequired ? `, Updated: ${t.updatedVersion}` : ''}
              </li>
            `).join('')}
          </ul>
          
          <div style="margin-top: 20px; border-top: 1px solid #e0e0e0; padding-top: 20px;">
            <h2 style="color: #7C3C43; font-size: 20px; font-weight: bold;">System Status</h2>
            <p><strong style="color: #344E6B;">WordPress Integrity Test:</strong> ${log.wordpressIntegrityResults}</p>
            <p><strong style="color: #344E6B;">Error Log Status:</strong> ${log.errorLogStatus}</p>
            ${log.errorLogStatus !== 'No Errors' ? `<p><strong style="color: #344E6B;">Error Log Details:</strong> ${log.errorLogDetails}</p>` : ''}
            <p><strong style="color: #344E6B;">Site Health Status:</strong> ${log.siteHealthStatus}</p>
            ${log.siteHealthStatus !== 'Good' ? `<p><strong style="color: #344E6B;">Site Health Details:</strong> ${log.siteHealthDetails}</p>` : ''}
            <h6 style="color: #7C3C43; font-size: 1.25rem; font-weight: bold; margin-top: 16px; margin-bottom: 8px;">Next Checkup Date</h6>
            <p>${log.nextCheckupDate.toLocaleDateString()}</p>
          </div>
        </div>
        
        <div style="margin-top: 20px; font-size: 12px; color: #111318;">
          This is an automated email from Service Boss. Please do not reply.
        </div>
      </div>
    `;
  };

  const sendEmailLog = async (log) => {
    try {
      const formattedLog = formatLogForEmail(log);
      const response = await axios.post('/api/send-maintenance-log.php', {
        to: log.emailTo,
        subject: `Maintenance Log for ${log.websiteName}`,
        htmlContent: formattedLog,
      });
      if (response.data.error) {
        throw new Error(response.data.error);
      }
      alert('Maintenance log email sent successfully!');
    } catch (error) {
      console.error('Failed to send email', error);
      alert('Failed to send maintenance log email. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      await onSubmit(log);
      await sendEmailLog(log);
      // Reset form (except websiteName)
      setLog({
        websiteName: websiteName || '',
        dateOfService: new Date(),
        plugins: [],
        themes: [],
        wordpress: { updateRequired: false, currentVersion: '', updatedVersion: '' },
        wordpressIntegrityResults: '',
        errorLogStatus: '',
        errorLogDetails: '',
        siteHealthStatus: '',
        siteHealthDetails: '',
        nextCheckupDate: new Date(),
        emailTo: '',
      });
    }
  };

  const handleAddItem = (type) => {
    setLog({
      ...log,
      [type]: [
        ...log[type],
        { name: '', currentVersion: '', updatedVersion: '', updateRequired: false }
      ]
    });
  };

  const handleRemoveItemConfirmation = (type, index) => {
    setDeleteConfirmation({ type, index });
  };

  const handleRemoveItem = () => {
    if (deleteConfirmation) {
      const { type, index } = deleteConfirmation;
      setLog({
        ...log,
        [type]: log[type].filter((_, i) => i !== index)
      });
      setDeleteConfirmation(null);
    }
  };

  const handleCancelDelete = () => {
    setDeleteConfirmation(null);
  };

  const handleItemChange = (type, index, field, value) => {
    const updatedItems = log[type].map((item, i) => 
      i === index ? { ...item, [field]: value } : item
    );
    setLog({ ...log, [type]: updatedItems });
  };

  const handleNextCheckupPreset = (preset) => {
    let newDate;
    switch (preset) {
      case '2weeks':
        newDate = addWeeks(log.dateOfService, 2);
        break;
      case '1month':
        newDate = addMonths(log.dateOfService, 1);
        break;
      case '3months':
        newDate = addMonths(log.dateOfService, 3);
        break;
      default:
        newDate = log.dateOfService;
    }
    setLog({ ...log, nextCheckupDate: newDate });
  };

  const renderItemFields = (type) => (
    <>
      <Typography variant="h6" gutterBottom>
        {type.charAt(0).toUpperCase() + type.slice(1)}
      </Typography>
      {log[type].map((item, index) => (
        <Box key={index} sx={{ mb: 4 }}> {/* Add margin bottom to each item */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    label="Name"
                    value={item.name}
                    onChange={(e) => handleItemChange(type, index, 'name', e.target.value)}
                    required
                    error={!!errors[`${type}[${index}].name`]}
                    helperText={errors[`${type}[${index}].name`]}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton onClick={() => handleRemoveItemConfirmation(type, index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Update Status"
                value={item.updateRequired ? 'Update Required' : 'No Update Required'}
                onChange={(e) => handleItemChange(type, index, 'updateRequired', e.target.value === 'Update Required')}
              >
                <MenuItem value="No Update Required">No Update Required</MenuItem>
                <MenuItem value="Update Required">Update Required</MenuItem>
              </TextField>
            </Grid>
            {item.updateRequired && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Current Version"
                    value={item.currentVersion}
                    onChange={(e) => handleItemChange(type, index, 'currentVersion', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Updated Version"
                    value={item.updatedVersion}
                    onChange={(e) => handleItemChange(type, index, 'updatedVersion', e.target.value)}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      ))}
      <Button
        startIcon={<AddIcon />}
        onClick={() => handleAddItem(type)}
        sx={{ mt: 2 }}
      >
        Add {type === 'plugins' ? 'Plugin' : 'Theme'}
      </Button>
    </>
  );

  return (
    <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
      <Typography variant="h5" gutterBottom>
        {initialValues ? 'Edit' : 'Add'} Maintenance Log
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Website Name"
              name="websiteName"
              value={log.websiteName}
              onChange={handleChange}
              required
              error={!!errors.websiteName}
              helperText={errors.websiteName}
              disabled={!!websiteName}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              label="Date of Service"
              value={log.dateOfService}
              onChange={handleDateChange('dateOfService')}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  fullWidth 
                  required
                  error={!!errors.dateOfService}
                  helperText={errors.dateOfService}
                />
              )}
            />
          </Grid>
          
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              WordPress
            </Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Current Version"
                  value={log.wordpress.currentVersion}
                  onChange={(e) => handleWordpressChange('currentVersion', e.target.value)}
                  required
                  error={!!errors.wordpress}
                  helperText={errors.wordpress}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Update Status</InputLabel>
                  <Select
                    value={log.wordpress.updateRequired}
                    onChange={(e) => handleWordpressChange('updateRequired', e.target.value)}
                  >
                    <MenuItem value={false}>No Update Required</MenuItem>
                    <MenuItem value={true}>Update Required</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {log.wordpress.updateRequired && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Updated Version"
                    value={log.wordpress.updatedVersion}
                    onChange={(e) => handleWordpressChange('updatedVersion', e.target.value)}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          
          <Grid item xs={12}>
            {renderItemFields('plugins')}
          </Grid>
          <Grid item xs={12}>
            {renderItemFields('themes')}
          </Grid>
          
          <Grid item xs={12}>
            <FormControl fullWidth error={!!errors.wordpressIntegrityResults}>
              <InputLabel>WordPress Integrity Test Results</InputLabel>
              <Select
                name="wordpressIntegrityResults"
                value={log.wordpressIntegrityResults}
                onChange={handleChange}
                required
              >
                <MenuItem value="Passed">Passed</MenuItem>
                <MenuItem value="Failed">Failed</MenuItem>
              </Select>
              {errors.wordpressIntegrityResults && <FormHelperText>{errors.wordpressIntegrityResults}</FormHelperText>}
            </FormControl>
          </Grid>
          
          <Grid item xs={12}>
            <FormControl fullWidth error={!!errors.errorLogStatus}>
              <InputLabel>Error Log Status</InputLabel>
              <Select
                name="errorLogStatus"
                value={log.errorLogStatus}
                onChange={handleChange}
                required
              >
                <MenuItem value="No Errors">No Errors</MenuItem>
                <MenuItem value="Minor Errors">Minor Errors</MenuItem>
                <MenuItem value="Major Errors">Major Errors</MenuItem>
              </Select>
              {errors.errorLogStatus && <FormHelperText>{errors.errorLogStatus}</FormHelperText>}
            </FormControl>
          </Grid>
          {(log.errorLogStatus === "Minor Errors" || log.errorLogStatus === "Major Errors") && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Error Log Details"
                name="errorLogDetails"
                value={log.errorLogDetails}
                onChange={handleChange}
              />
            </Grid>
          )}
          
          <Grid item xs={12}>
            <FormControl fullWidth error={!!errors.siteHealthStatus}>
              <InputLabel>Site Health Status</InputLabel>
              <Select
                name="siteHealthStatus"
                value={log.siteHealthStatus}
                onChange={handleChange}
                required
              >
                <MenuItem value="Good">Good</MenuItem>
                <MenuItem value="Needs Improvement">Needs Improvement</MenuItem>
                <MenuItem value="Critical">Critical</MenuItem>
              </Select>
              {errors.siteHealthStatus && <FormHelperText>{errors.siteHealthStatus}</FormHelperText>}
            </FormControl>
          </Grid>
          {(log.siteHealthStatus === "Needs Improvement" || log.siteHealthStatus === "Critical") && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Site Health Details"
                name="siteHealthDetails"
                value={log.siteHealthDetails}
                onChange={handleChange}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Next Checkup Date 
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DatePicker
                  label="Custom Date"
                  value={log.nextCheckupDate}
                  onChange={handleDateChange('nextCheckupDate')}
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      fullWidth 
                      required
                      error={!!errors.nextCheckupDate}
                      helperText={errors.nextCheckupDate}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item>
                    <Button variant="text" onClick={() => handleDateChange('nextCheckupDate')(addWeeks(new Date(log.dateOfService), 2))}>
                      +2 Weeks
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="text" onClick={() => handleDateChange('nextCheckupDate')(addMonths(new Date(log.dateOfService), 1))}>
                      +1 Month
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="text" onClick={() => handleDateChange('nextCheckupDate')(addMonths(new Date(log.dateOfService), 3))}>
                      +3 Months
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email To"
              name="emailTo"
              value={log.emailTo}
              onChange={handleChange}
              type="email"
              required
              error={!!errors.emailTo}
              helperText={errors.emailTo}
            />
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              {initialValues ? 'Update' : 'Save'} Log
            </Button>
          </Grid>
        </Grid>
      </form>
      <Dialog
        open={!!deleteConfirmation}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this {deleteConfirmation?.type.slice(0, -1)}? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleRemoveItem} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default MaintenanceLogForm;
